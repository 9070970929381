const domain = `https://genivisor-api.reports.geniusee.space`;

export const askQuestion = async ({
  questionInput,
  userId,
  callback,
  errorCallBack,
  chatId,
}: {
  questionInput: string;
  userId: string;
  chatId: string;
  callback: (answerText: string) => void;
  errorCallBack: () => void;
}) => {
  try {
    const response = await fetch(
      `${domain}/ask_tax_code?query=${encodeURIComponent(
        questionInput
      )}&user_id=${encodeURIComponent(userId)}&dialog_id=${encodeURIComponent(chatId)}`,
      {
        method: 'GET',
      }
    );

    const reader = response.body?.getReader();
    let answerText = '';
    while (true) {
      const stream = await reader?.read();

      if (stream?.done) {
        break;
      }

      answerText = answerText + new TextDecoder().decode(stream?.value);
      callback(answerText);
    }

    return answerText;
  } catch (error) {
    console.log(error);
    errorCallBack();
  }
};
