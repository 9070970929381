import { useUser } from '@context/User';

import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

import { ChatInterface, MessageInterface } from '@core/types';

interface ChatContextInterface {
  chats: Array<ChatInterface>;
  handleSetMessage: ({ chatId, message }: { chatId: string; message: MessageInterface }) => void;
  handleDeleteMessage: ({ chatId, messageId }: { chatId: string; messageId: string }) => void;
  handleCreateChat: () => string;
  handleDeleteChat: (chatId: string) => Promise<string>;
  setMessages: (messages: Array<MessageInterface>, chatId: string) => void;
  loading: boolean;
}
const domain = `https://genivisor-api.reports.geniusee.space`;

const ChatContext = createContext<ChatContextInterface>({
  chats: [],
  handleSetMessage: () => {},
  handleCreateChat: () => '',
  handleDeleteChat: () => new Promise<string>(() => ''),
  handleDeleteMessage: () => {},
  setMessages: () => {},
  loading: false,
});

const ChatProvider = ({ children }: { children: ReactNode }) => {
  const [chats, setChats] = useState<Array<ChatInterface>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useUser();

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        setLoading(true);
        const response = await fetch(domain + '/user_dialogs/' + user.username);
        const data = await response.json();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newChats = Object.entries(data.dialogs).map(([chatId, title]) => {
          return {
            id: chatId,
            date: Number(new Date()),
            messages: [
              {
                id: uuidv4(),
                text: title,
                like: false,
                dislike: false,
                sender: {
                  id: 'user',
                  avatar: '',
                  firstName: '',
                  lastName: '',
                },
                date: Number(new Date().valueOf()),
                feedback: '',
                error: false,
                errorMsg: '',
                showAskExternalBtn: false,
                userId: user.username,
                currentChatId: chatId,
              },
            ],
          };
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setChats(newChats.reverse());
        setLoading(false);
      } catch (error) {
        console.error('Error fetching chat history:', error);
      }
    };

    if (user) {
      fetchHistory();
    }
  }, [user]);

  const handleSetMessage = useCallback(
    ({ chatId, message }: { chatId: string; message: MessageInterface }) => {
      setChats((chats: Array<ChatInterface> | []) => {
        const isChatExists = chats?.some((chat) => chat.id === chatId);
        if (isChatExists) {
          return chats?.map((chat) => {
            if (chat.id === chatId) {
              return {
                ...chat,
                messages: updateMessages(chat.messages, message),
              };
            }
            return chat;
          });
        } else {
          return [
            ...(chats || []),
            {
              id: chatId,
              date: new Date().valueOf(),
              messages: [message],
            },
          ];
        }
      });
    },
    []
  );

  const setMessages = (messages: Array<MessageInterface>, chatId: string) => {
    setChats((chats: Array<ChatInterface>) => {
      const chat = chats.find((chat) => chat.id === chatId);
      if (chat) {
        return chats.map((chat) => {
          if (chat.id === chatId) {
            return {
              ...chat,
              messages: messages,
            };
          }
          return chat;
        });
      } else {
        return [
          ...chats,
          {
            id: chatId,
            date: new Date().valueOf(),
            messages: messages,
          },
        ];
      }
    });
  };

  const updateMessages = (messages: Array<MessageInterface>, newChatMessage: MessageInterface) => {
    if (messages && messages?.length > 0) {
      const isMessageExists = messages.some((message) => message.id === newChatMessage.id);
      if (isMessageExists) {
        return messages.map((message) => {
          if (message.id === newChatMessage.id) {
            return newChatMessage;
          }
          return message;
        });
      }
      return [...messages, newChatMessage];
    } else {
      return [newChatMessage];
    }
  };

  const handleCreateChat = useCallback(() => {
    const chatId = uuidv4();
    setChats((chats: Array<ChatInterface>) => {
      // delete previously created empty chats
      const newChats = chats.filter((chat) => chat.messages.length > 0);
      // and create a new chat
      return [...newChats, { id: chatId, date: new Date().valueOf(), messages: [] }];
    });
    return chatId;
  }, []);

  const handleDeleteChat = useCallback(
    async (chatId: string) => {
      await fetch(domain + '/dialog/' + chatId + '/' + user.username, {
        method: 'DELETE',
      })
        .then((response) => response.json())
        .then(() => {
          setChats((chats: Array<ChatInterface>) => {
            return chats.filter((chat) => chat.id !== chatId);
          });
        })
        .catch(() => {
          setChats((chats: Array<ChatInterface>) => {
            return chats.filter((chat) => chat.id !== chatId);
          });
        });

      return chatId;
    },
    [user]
  );

  const handleDeleteMessage = useCallback(
    ({ chatId, messageId }: { chatId: string; messageId: string }) => {
      setChats((chats: Array<ChatInterface>) => {
        return chats.map((chat) => {
          if (chat.id === chatId) {
            return {
              ...chat,
              messages: chat.messages.filter((message) => message.id !== messageId),
            };
          }
          return chat;
        });
      });
    },
    []
  );

  return (
    <ChatContext.Provider
      value={{
        chats,
        handleSetMessage,
        handleCreateChat,
        handleDeleteChat,
        handleDeleteMessage,
        loading,
        setMessages,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChats = () => useContext(ChatContext);

export default ChatProvider;
