import { MessageInterface } from '@core/types';

interface LegalResponse {
  id: number;
  userId: string;
  message: string;
  response: string;
  dateCreated: string; // Using string to represent ISO date format
}

export default async function fetchMessages(chatId: string, userId: string) {
  const domain = `https://genivisor-api.reports.geniusee.space`;
  try {
    const response = await fetch(`${domain}/dialog/${chatId}/${userId}`);

    const data = (await response.json()) as LegalResponse[];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data.reduce<MessageInterface[]>((acc, message) => {
      const question = message.message;
      const answer = message.response;

      return [
        ...acc,
        {
          id: 'user_' + message.id,
          text: question,
          like: false,
          dislike: false,
          sender: {
            id: 'user',
            avatar: '',
            firstName: '',
            lastName: '',
          },
          date: new Date(message.dateCreated).getTime(),
          feedback: '',
          error: false,
          errorMsg: '',
          showAskExternalBtn: false,
          userId: userId,
          currentChatId: chatId,
        },
        {
          id: 'genivisor_' + message.id,
          text: answer,
          like: false,
          dislike: false,
          sender: {
            id: 'genivisor',
            avatar: '',
            firstName: '',
            lastName: '',
          },
          date: new Date(message.dateCreated).getTime(),
          feedback: '',
          error: false,
          errorMsg: '',
          showAskExternalBtn: false,
          userId: userId,
          currentChatId: chatId,
        },
      ];
    }, []);
  } catch (error) {
    console.log(error);
  }
}
