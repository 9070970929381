import React, { FC, useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, Modal, Portal, Text, TextInput } from 'react-native-paper';

import colors from '@core/colors/colors';
import { screenWidth } from '@core/constants';
import { useLocalize } from '@core/hooks/localize';
import radii from '@core/styles/radii';
import { sharedStyles } from '@core/styles/sharedStyles';
import { stylesByPlatform } from '@core/utils';

import Spacer from './Spacer';

interface FeedbackModalProps {
  visible: boolean;
  onClose: () => void;
  onSend: (message: string) => void;
  defaultFeedback: string;
  isLoading: boolean;
}

const FeedbackModal: FC<FeedbackModalProps> = ({
  visible,
  onClose,
  onSend,
  defaultFeedback,
  isLoading,
}) => {
  const { t } = useLocalize();
  const [message, setMessage] = useState<string>(defaultFeedback);

  const handleChangeText = useCallback((text: string) => {
    setMessage(text);
  }, []);

  const handleSendFeedback = useCallback(() => {
    onSend(message);
  }, [message, onSend]);

  return (
    <Portal>
      <Modal visible={visible} onDismiss={onClose}>
        <View
          style={stylesByPlatform({
            mobileStyle: sharedStyles.paddingHorizontal16,
            webStyle: styles.containerWeb,
          })}
        >
          <View style={styles.containerStyle}>
            <Text variant={'titleMedium'} style={styles.title}>
              {t('home.modal.title')}
            </Text>

            <Spacer height={12} />

            <TextInput
              mode="outlined"
              label={t('home.input.message')}
              placeholder={t('home.input.message')}
              defaultValue={defaultFeedback}
              onChangeText={handleChangeText}
              multiline={true}
              numberOfLines={5}
              activeOutlineColor={colors.purple2}
              placeholderTextColor={colors.gray2}
              style={styles.input}
              outlineStyle={styles.borderRadius}
              editable={!isLoading}
              blurOnSubmit={false}
            />

            <Spacer height={12} />

            <View style={styles.buttonContainer}>
              <Button textColor={colors.black} labelStyle={sharedStyles.center} onPress={onClose}>
                <Text style={styles.buttonText}>{t('home.modal.cancel')}</Text>
              </Button>

              <Spacer width={16} />

              <Button
                mode="contained"
                buttonColor={colors.black}
                onPress={handleSendFeedback}
                loading={isLoading}
              >
                <Text style={[{ color: colors.white }, styles.buttonText]}>
                  {t('home.modal.send')}
                </Text>
              </Button>
            </View>
          </View>
        </View>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  containerWeb: { padding: (screenWidth - 650) / 2 },
  title: { fontSize: 18, fontWeight: '700' },
  buttonContainer: { flexDirection: 'row', justifyContent: 'flex-end' },
  buttonText: { fontSize: 16, fontWeight: '600' },
  containerStyle: { backgroundColor: colors.white, padding: 20, borderRadius: radii.button },
  input: {
    backgroundColor: colors.white,
    height: 126,
  },
  borderRadius: { borderRadius: radii.button },
});

export default FeedbackModal;
