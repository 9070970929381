const domain = `https://genivisor-api.reports.geniusee.space`;

interface AskQuestionProps {
  query: string;
  userId: string;
  answer: string;
  errorCallBack: (error: Error) => void;
}

interface Response {
  accuracy_rate: number;
  is_answered: boolean;
}

export default async function isQuestionAnswered({
  answer,
  query,
  userId,
  errorCallBack,
}: AskQuestionProps): Promise<Response> {
  return await fetch(
    domain +
      '/is_question_answered?query=' +
      encodeURIComponent(query) +
      '&answer=' +
      encodeURIComponent(answer) +
      '&user_id =' +
      encodeURIComponent(userId)
  )
    .then(async (response) => {
      return (await response.json()) as Response;
    })
    .catch((error) => {
      errorCallBack(error as Error);
      return { accuracy_rate: 0, is_answered: false };
    });
}
